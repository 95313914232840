import DeGodsComparison from "./components/DeGodsComparison";

function App() {
  return (

      <DeGodsComparison />
  );
}

export default App;
